import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate, translate as t } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { IconListDetails } from '../../../common/icons/cdl/ListDetails';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../../common/TabSwitch/TabSwitch';
import { Box } from '../../../common/ui/Box';
import { CircleValidity } from '../../../common/Validity/CircleValidity';
import { getTableValidity } from '../../../common/Validity/getTableValidity';
import { UnreadIndicator } from '../../../order/common/UnreadIndicator';
import { Port } from '../../../port/Port';
import { Route } from '../../../routes/_app.fuel.offers';
import { OfferModel } from '../../model/OfferModel';
import { useOfferPagination } from '../../useOfferPagination';
import { OfferActions } from '../actions/OfferActions';

import { fuelTabToOfferStateMap } from './fuelTabToOfferStateMap';
import { useFuelOfferPaginationTotals } from './useFuelOfferPaginationTotals';
import { selectSortOption } from './utils/selectSortOption';

const FuelOfferTabs = {
    ENQUIRED: 'enquired',
    OFFERED: 'offered',
    ORDERED: 'ordered',
    ACKNOWLEDGED: 'acknowledged',
    CONFIRMED: 'confirmed',
    DELIVERED: 'delivered',
    CANCELED: 'canceled',
    CLOSED: 'closed',
} as const;

// eslint-disable-next-line no-redeclare
type FuelOfferTabs = (typeof FuelOfferTabs)[keyof typeof FuelOfferTabs];

export const FuelOfferOverviewTable = () => {
    const { context } = useProductContext();
    const role = useRole();

    const handleSupplierIds = (supplierIds: string[]) => {
        if (supplierIds.length) {
            return supplierIds;
        }

        if (role.isAdmin()) {
            return undefined;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    const { data: offersTotals = {} } = useFuelOfferPaginationTotals({
        searchQuery: search.searchQuery,
        supplierIds: handleSupplierIds(search.supplierIds),
        customerIds: search.customerIds,
    });

    const offerPaginationQuery = useOfferPagination({
        page: search.page,
        searchQuery: search.searchQuery,
        supplierIds: handleSupplierIds(search.supplierIds),
        customerIds: search.customerIds,
        types: [ProductContext.FUEL],
        states: fuelTabToOfferStateMap[search.tab],
        sortField: search.sortField,
        sortDirection: search.sortDirection,
    });

    const onTabSelect = (tab: FuelOfferTabs) => {
        navigate({
            search: (previousSearch) => ({
                ...previousSearch,
                sortField: selectSortOption(tab).sortField,
                sortDirection: selectSortOption(tab).sortDirection,
                tab,
                page: 0,
            }),
        });
    };

    if (offerPaginationQuery.isPending) {
        return (
            <div>
                <TabSwitch selectedContext={search.tab} onSelect={onTabSelect} totals={offersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (offerPaginationQuery.isError) {
        return (
            <div>
                <TabSwitch selectedContext={search.tab} onSelect={onTabSelect} totals={offersTotals} />
                <Error />
            </div>
        );
    }

    if (!offerPaginationQuery.data.content.length) {
        return (
            <div>
                <TabSwitch selectedContext={search.tab} onSelect={onTabSelect} totals={offersTotals} />
                <TableEmptyState Icon={IconListDetails} text={t('order.emptylist')} />
            </div>
        );
    }

    return (
        <div>
            <TabSwitch selectedContext={search.tab} onSelect={onTabSelect} totals={offersTotals} />
            <TableBuilder<OfferModel>
                data={offerPaginationQuery.data.content}
                onRowSelect={(offer) => {
                    navigate({ to: `/fuel/offer/$id`, params: { id: offer.id } });
                }}
                isLoading={offerPaginationQuery.isPlaceholderData && offerPaginationQuery.isFetching}
            >
                {role.isSupplier() ? (
                    <TableBuilderColumn<OfferModel> header="" width="1%" hideOnLoading>
                        {(offer) => <Box width="8px">{!offer.supplierRead ? <UnreadIndicator /> : null}</Box>}
                    </TableBuilderColumn>
                ) : null}

                {search.tab !== FuelOfferTabs.ENQUIRED ? (
                    <TableBuilderColumn<OfferModel> header={translate('order.vendorreferenceshort')} width="10%">
                        {(offer) => offer.vendorReference || '-'}
                    </TableBuilderColumn>
                ) : null}

                <TableBuilderColumn<OfferModel>
                    header={role.isSupplier() ? translate('offer.company') : translate('offer.supplier')}
                    width="10%"
                >
                    {(offer) => formatCompanyName({ company: offer.supplier })}
                </TableBuilderColumn>

                <TableBuilderColumn<OfferModel> header={translate('offer.customer')} width="15%">
                    {(offer) => formatCompanyName({ company: offer.customer })}
                </TableBuilderColumn>

                <TableBuilderColumn<OfferModel> header={translate('order.vessel')} width="10%">
                    {(offer) => formatVessel({ vessel: offer.vessel, short: true })}
                </TableBuilderColumn>

                <TableBuilderColumn<OfferModel> header={translate('order.port')} width="15%">
                    {(offer) => {
                        if (!offer.port) {
                            return null;
                        }
                        return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
                    }}
                </TableBuilderColumn>

                {!['enquired', 'closed'].includes(search.tab) ? (
                    <TableBuilderColumn<OfferModel> header={translate('order.dateDeliveryShort')} width="10%">
                        {(offer) => {
                            if (!offer.dateDelivery) {
                                return null;
                            }
                            return formatDate({
                                date: offer.dateDelivery,
                                timeZone: 'UTC',
                            });
                        }}
                    </TableBuilderColumn>
                ) : null}

                {search.tab === FuelOfferTabs.ENQUIRED ? (
                    <TableBuilderColumn<OfferModel> header={translate('order.eta')} width="15%">
                        {(offer) => {
                            if (!offer.eta) {
                                return null;
                            }
                            return formatDateTime({
                                date: offer.eta,
                                timeZoneId: offer.port?.timeZoneId,
                            });
                        }}
                    </TableBuilderColumn>
                ) : null}

                {['ordered', 'canceled'].includes(search.tab) ? (
                    <TableBuilderColumn<OfferModel> header={translate('order.status')} width="10%">
                        {(offer) => {
                            return <OfferStatusTag state={offer.state} variant="supplier" />;
                        }}
                    </TableBuilderColumn>
                ) : null}

                {search.tab === FuelOfferTabs.ENQUIRED ? (
                    <TableBuilderColumn<OfferModel> header={translate('order.enquiryValidity')} width="10%">
                        {(offer) => {
                            if (!offer.validUntil && !offer.validityTime) {
                                return null;
                            }
                            return (
                                <CircleValidity
                                    validityTime={offer.validityTime!}
                                    validUntil={offer.validUntil!}
                                    background="transparent"
                                />
                            );
                        }}
                    </TableBuilderColumn>
                ) : null}

                {search.tab === FuelOfferTabs.OFFERED ? (
                    <TableBuilderColumn<OfferModel> header={translate('order.quoteValidityBy')} width="10%">
                        {(offer) => {
                            if (!offer.quoteValidUntil) {
                                return null;
                            }
                            return getTableValidity(offer.quoteValidUntil);
                        }}
                    </TableBuilderColumn>
                ) : null}

                {role.isSupplier() ? (
                    <TableBuilderColumn<OfferModel> header="" width="5%" hideOnLoading>
                        {(offer) => <OfferActions offer={offer} />}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>

            <CenteredPagination
                pageTotal={offerPaginationQuery.pageTotal}
                currentPage={search.page}
                onPageChange={(page) => navigate({ search: (previousSearch) => ({ ...previousSearch, page: page }) })}
            />
        </div>
    );
};
