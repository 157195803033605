import { LubesCreateOrderRequest } from '../../../../common/api/clients/order.api';
import { OrderState } from '../../../model/OrderModel';
import { FormikCreateEnquiryValues } from '../LubesCreateEnquiryPage';

export const buildOrderRequest = (values: FormikCreateEnquiryValues, state: OrderState): LubesCreateOrderRequest => {
    return {
        state: state,
        vesselId: values.vesselId,
        customerId: values.customerId,
        agentId: values.agentId || undefined,
        assignedSupplierIds: values.assignedSupplierIds?.length ? values.assignedSupplierIds : undefined,
        buyerReference: values.buyerReference ? values.buyerReference : undefined,
        comment: values.comment || undefined,
        dateDelivery: values.dateDelivery ? values.dateDelivery : undefined,
        items: values.items
            .filter((item) => item.productId || item.units)
            .map((item) => ({
                productId: item.productId || undefined,
                packType: item.packType,
                unitSize: item.unitSize,
                units: item.units ? Number(item.units) : undefined,
                unit: item.unit,
                volume: item.volume || undefined,
                deleted: item.deleted,
            })),
        portId: values.portId || undefined,
        receiverSupplierIds: values.receiverSupplierIds?.length ? values.receiverSupplierIds : undefined,
        samplekits: values.samplekits?.length
            ? values.samplekits.filter((sampleKit) => sampleKit.name || sampleKit.quantity)
            : undefined,
        spot: values.spot,
        testOrder: values.testOrder,
    };
};
