import { createFileRoute, Outlet } from '@tanstack/react-router';
import { AblyProvider } from 'ably/react';
import { LDProvider } from 'launchdarkly-react-client-sdk';
import { Fragment, ReactNode } from 'react';

import { DrawerAppWrapper } from '../cdl/Drawer/DrawerAppWrapper';
import { usePrefetchQueries } from '../common/hooks/usePrefetchQueries';
import { client as launchDarklyClient } from '../common/launchDarkly/launchDarklyClient';
import { AblyService } from '../common/services/AblyService';
import AppSettings from '../constants';
import { AcceptTerms } from '../group/terms/AcceptTerms';
import { requireAuthentication } from '../lib/auth';
import { SessionService } from '../user/session/SessionService';

const MaybeDarkly = (props: { children: ReactNode }) => {
    return launchDarklyClient ? (
        <LDProvider clientSideID={AppSettings.launchDarklyClientSideId} ldClient={launchDarklyClient!} {...props} />
    ) : (
        <Fragment />
    );
};

const MaybeAbly = (props: { children: ReactNode }) => {
    const ablyClient = AblyService.getClient();

    return ablyClient ? <AblyProvider client={ablyClient} {...props} /> : <Fragment />;
};

const App = () => {
    usePrefetchQueries();

    return (
        <MaybeDarkly>
            <MaybeAbly>
                <DrawerAppWrapper>
                    <Outlet />
                    <AcceptTerms />
                </DrawerAppWrapper>
            </MaybeAbly>
        </MaybeDarkly>
    );
};

export const Route = createFileRoute('/_app')({
    beforeLoad: async ({ context }) => {
        requireAuthentication();
        if (!context.hasLoadedRoleInformation) {
            await SessionService.getInstance().loadRoleInformation();
            return { ...context, hasLoadedRoleInformation: true };
        }
        return context;
    },
    component: App,
});
