import { AgentMessage } from '../../../types/AgentMessage';
import { CustomerCompanyMessage } from '../../../types/CustomerCompanyMessage';
import { PortMessage } from '../../../types/PortMessage';
import { ProductContext } from '../../../types/ProductContext';
import { SupplierCompanyMessage } from '../../../types/SupplierCompanyMessage';
import { VesselMessage } from '../../../types/VesselMessage';
import { ItemModel } from '../../common/models/ItemModel';
import { SamplekitModel } from '../../common/models/SamplekitModel';
import { OfferState } from '../../offer/model/OfferState';

export enum OrderState {
    DRAFT = 'DRAFT',
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
    CANCELED = 'CANCELED',
}

export interface OrderModel {
    id: string;
    vesselId: string;
    vessel: VesselMessage;
    state: OrderState;
    buyerReference?: string;
    agentId?: string;
    agentInformation?: AgentMessage;
    customerId: string;
    customer: CustomerCompanyMessage;
    supplier?: SupplierCompanyMessage;
    comment: string;
    creationComment?: string;
    port?: PortMessage;
    portId: string;
    dateDelivery?: number;
    chosenOfferId?: string;
    offerCount: number;
    receiverSupplierIds: string[];
    assignedSupplierIds: string[];
    spot: boolean;
    type: ProductContext;
    testOrder: boolean;
    orderNumber?: string;
    items: ItemModel[];
    samplekits: SamplekitModel[];
    read: boolean;
    offerState?: OfferState;
    dateCreated: number;
    eta?: number;
    validUntil?: number;
    isClosed(): boolean;
    isDraft(): boolean;
    isFinished(): boolean;
    isOpen(): boolean;
    isSpot(): boolean;
}
