import { useNavigate } from '@tanstack/react-router';
import { useRef, useState } from 'react';

import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatPort } from '../../../common/helpers/formatPort.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { OfferStatusTag } from '../../../common/OfferStatusTag/OfferStatusTag';
import { Section } from '../../../common/Section/Section';
import { OfferModel } from '../../../offer/model/OfferModel';
import { useOfferPagination } from '../../../offer/useOfferPagination';
import { RecentActivitiesEmptyState } from '../../common/components/RecentActivitiesEmptyState';

export const LubesSupplierOfferTable = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState(0);
    const role = useRole();
    const navigate = useNavigate();

    const paginatedOffersQuery = useOfferPagination({
        page,
        supplierRead: false,
        types: [ProductContext.LUBES],
    });

    if (paginatedOffersQuery.isPending) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (paginatedOffersQuery.isError) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <Error />
            </Section>
        );
    }

    if (!paginatedOffersQuery.data?.content.length) {
        return (
            <Section title={translate('dashboard.recentactivities')}>
                <RecentActivitiesEmptyState />
            </Section>
        );
    }

    const openOffer = (offer: OfferModel) => {
        navigate({ to: '/offer/$id', params: { id: offer.id } });
    };

    return (
        <div>
            <Section title={translate('dashboard.recentactivities')}>
                <TableBuilder<OfferModel>
                    data={paginatedOffersQuery.data.content}
                    onRowSelect={(offer) => openOffer(offer)}
                    isLoading={paginatedOffersQuery.isPlaceholderData && paginatedOffersQuery.isFetching}
                >
                    <TableBuilderColumn<OfferModel> header={translate('order.vendorreferenceshort')} width="10%">
                        {(offer) => offer.vendorReference || '-'}
                    </TableBuilderColumn>

                    {!role.isOneCompanyUser(ProductContext.LUBES) && (
                        <TableBuilderColumn<OfferModel> header={translate('offer.company')} width="10%">
                            {(offer) => offer.supplier.name}
                        </TableBuilderColumn>
                    )}

                    <TableBuilderColumn<OfferModel> header={translate('offer.customer')} width="15%">
                        {(offer) => offer.customer.name}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OfferModel> header={translate('vessel.name')} width="15%">
                        {(offer) => formatVessel({ vessel: offer.vessel, short: true })}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OfferModel> header={translate('order.port')} width="20%">
                        {(offer) => formatPort({ port: offer.port, locode: false })}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OfferModel> header={translate('order.dateDelivery')} width="15%">
                        {(offer) => {
                            if (!offer.dateDelivery) {
                                return null;
                            }
                            return formatDate({ date: offer.dateDelivery, timeZone: 'UTC' });
                        }}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OfferModel> header={translate('order.status')} width="15%">
                        {(offer) => <OfferStatusTag state={offer.state} variant="supplier" />}
                    </TableBuilderColumn>
                </TableBuilder>

                <CenteredPagination
                    pageTotal={paginatedOffersQuery.pageTotal}
                    currentPage={page}
                    onPageChange={(page) => {
                        setPage(page);
                        if (wrapperRef.current) {
                            window.scrollTo(0, wrapperRef.current.offsetTop);
                        }
                    }}
                />
            </Section>
        </div>
    );
};
