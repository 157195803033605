import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';

import { VesselSearchSchema } from '../../types/vesselDetailsSearchSchema';
import { queries } from '../common/api/queryKeys/queries';
import { getRole } from '../common/hooks/useRole';
import { queryClient } from '../lib/queryClient';
import { LubesVesselDetailPage } from '../vessel/VesselDetailPage/LubesVesselDetailPage';

export const Route = createFileRoute('/_app/_lubes/vessel/$id')({
    validateSearch: zodSearchValidator(VesselSearchSchema),
    component: LubesVesselDetailPage,
    async loader({ params: { id } }) {
        queryClient.fetchQuery({ ...queries.vessels.detail(id) }).then((vessel) => {
            getRole().updateContext(vessel?.customer.type);
        });
    },
});
