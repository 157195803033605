import { PortMessage } from '../../../../../types/PortMessage';
import { Pagination } from '../../../../cdl/Pagination/Pagination';
import { TableBuilder } from '../../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../../common/Error/Error';
import { translate } from '../../../../common/helpers/translate.helper';
import { IconList } from '../../../../common/icons/cdl/List';
import { LoadingIndicator } from '../../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../../common/Section/Section';
import { TableEmptyState } from '../../../../common/TableEmptyState/TableEmptyState';
import { Box } from '../../../../common/ui/Box';
import { Route } from '../../../../routes/_app.ports';
import { formatPortAliases } from '../../formatPortAliases';
import { usePortPagination } from '../hooks/usePortPagination';

export const PortsOverviewTable = () => {
    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    const portsPaginationQuery = usePortPagination(search.page, search.searchQuery);

    if (portsPaginationQuery.isPending) {
        return (
            <Section>
                <LoadingIndicator />
            </Section>
        );
    }

    if (portsPaginationQuery.isError) {
        return (
            <Section>
                <Error />
            </Section>
        );
    }

    if (!portsPaginationQuery.data?.content.length) {
        return (
            <Section>
                <TableEmptyState Icon={IconList} text={translate('port.emptyList')} />
            </Section>
        );
    }

    return (
        <div>
            <TableBuilder<PortMessage>
                data={portsPaginationQuery.data.content}
                onRowSelect={(row) => {
                    navigate({ to: '/port/detail/$id', params: { id: row.id } });
                }}
            >
                <TableBuilderColumn<PortMessage> width="20%" header={translate('port.name')}>
                    {(port) => port.name}
                </TableBuilderColumn>
                <TableBuilderColumn<PortMessage> width="15%" header={translate('port.locode')}>
                    {(port) => port.locCode}
                </TableBuilderColumn>
                <TableBuilderColumn<PortMessage> width="20%" header={translate('port.country')}>
                    {(port) => port.country.name}
                </TableBuilderColumn>
                <TableBuilderColumn<PortMessage> width="20%" header={translate('port.aliases')}>
                    {(port) => formatPortAliases(port.aliases)}
                </TableBuilderColumn>
                <TableBuilderColumn<PortMessage> width="25%" header={translate('port.locCodeAliases')}>
                    {(port) => formatPortAliases(port.locCodeAliases)}
                </TableBuilderColumn>
            </TableBuilder>

            {portsPaginationQuery.pageTotal > 1 ? (
                <Box display="flex" justifyContent="center" marginTop={6}>
                    <Pagination
                        currentPage={search.page}
                        pageTotal={portsPaginationQuery.pageTotal}
                        onPageChange={(page) =>
                            navigate({
                                search: (previousSearch) => ({ ...previousSearch, page }),
                            })
                        }
                    />
                </Box>
            ) : null}
        </div>
    );
};
