import { useNavigate } from '@tanstack/react-router';
import { Formik } from 'formik';

import { Error } from '../../common/Error/Error';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { createOverlay } from '../../common/Overlay/Overlay';
import { useOrder } from '../lubes/hooks/useOrder';
import { useOrderEdit } from '../lubes/hooks/useOrderEdit';
import { validateOrder } from '../schema';

import { FormikEditOrderValues, OrderEditForm } from './OrderEditForm';

export const OrderEditPage = () => {
    const { id } = useAngularRouterLocation<{ id: string }>();
    const { data: order, isPending, isError } = useOrder(id);
    const orderEditMutation = useOrderEdit();
    const navigate = useNavigate();

    if (isPending) {
        return <LoadingIndicator variant="page" />;
    }

    if (isError) {
        return <Error />;
    }

    const initialValues: FormikEditOrderValues = {
        id: order.id,
        vesselId: order.vesselId,
        customerId: order.customerId,
        portId: order.portId,
        dateDelivery: order.dateDelivery || null,
        buyerReference: order.buyerReference || '',
        agentId: order.agentId || '',
        items: order.items,
        samplekits: order.samplekits,
        state: order.state,
    };

    const onSubmit = async (values: FormikEditOrderValues) => {
        const order = {
            ...values,
            buyerReference: values.buyerReference !== '' ? values.buyerReference : undefined,
            agentId: values.agentId !== '' ? values.agentId : undefined,
        };

        await orderEditMutation.mutateAsync({
            orderId: values.id,
            order,
        });

        navigate({ to: '/order/$id', params: { id: values.id } });
    };

    return createOverlay(
        <Formik<FormikEditOrderValues>
            initialValues={initialValues}
            component={OrderEditForm}
            validateOnMount
            validate={validateOrder}
            onSubmit={onSubmit}
        />
    );
};
