import { translate } from '../../helpers/translate.helper';
import { SidebarNavigationItem } from '../components/SidebarNavigationItem';

export const GeneralAdminSidebarItems = () => {
    return (
        <>
            <SidebarNavigationItem to="/vessels">{translate('navigation.vessels')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/customer-groups">{translate('navigation.customers')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/supplier-groups">{translate('navigation.suppliers')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/agents">{translate('navigation.agents')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/assignments">{translate('navigation.assignments')}</SidebarNavigationItem>

            <SidebarNavigationItem to="/ports">{translate('navigation.ports')}</SidebarNavigationItem>
        </>
    );
};
