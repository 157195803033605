import { useMutation, useQueryClient } from '@tanstack/react-query';

import { VesselUpdateRequestMessage } from '../../../../types/VesselUpdateRequestMessage';
import { useToasts } from '../../../cdl/Toast/useToasts';
import {
    createLubricationChart,
    deleteLubricationChart,
    updateVessel as updateVesselApi,
} from '../../../common/api/clients/vessel.api';
import { queryKeys } from '../../../common/api/queryKeys';
import { queries } from '../../../common/api/queryKeys/queries';
import { translate } from '../../../common/helpers/translate.helper';

export enum FileMode {
    ADDED = 'ADDED',
    REMOVED = 'REMOVED',
}

interface UpdateVesselProps {
    vesselId: string;
    vessel: VesselUpdateRequestMessage;
    lubricationChart?: File;
    fileMode?: FileMode;
}

const updateVessel = async ({ vesselId, vessel, lubricationChart, fileMode }: UpdateVesselProps) => {
    if (lubricationChart && fileMode === FileMode.ADDED) {
        await updateVesselApi({
            vesselId,
            vessel,
        });
        return createLubricationChart({
            vesselId,
            file: lubricationChart,
        });
    } else if (fileMode === FileMode.REMOVED) {
        await updateVesselApi({
            vesselId,
            vessel,
        });
        return deleteLubricationChart(vessel.id);
    }

    return updateVesselApi({
        vesselId: vessel.id,
        vessel,
    });
};

export const useVesselMutation = (id: string) => {
    const queryClient = useQueryClient();
    const { addToast, addErrorToast } = useToasts();

    return useMutation({
        mutationFn: updateVessel,

        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queries.vessels.detail(id).queryKey });
            queryClient.invalidateQueries({ queryKey: queryKeys.schedulesWithPrices(id) });

            addToast(translate('vessel.vesselUpdateSuccessToast'));
        },

        onError: () => {
            addErrorToast(translate('vessel.vesselUpdateErrorToast'));
        },
    });
};
