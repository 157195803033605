import { ProductContext } from '../../../../types/ProductContext';
import { ProductMessage } from '../../../../types/ProductMessage';
import { ProductUnit } from '../../../../types/ProductUnit';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { useToasts } from '../../../cdl/Toast/useToasts';
import { translate } from '../../../common/helpers/translate.helper';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { useProductUpdate } from '../hooks/useProductUpdate';

import { ProductFormState } from './ProductBaseForm';
import { ProductForm } from './ProductForm';

interface UpdateProductButtonProps {
    product: ProductMessage;
}

export const UpdateProductButton = (props: UpdateProductButtonProps) => {
    const { push, pop } = useDrawer();
    const { addToast, addErrorToast } = useToasts();
    const productUpdateMutation = useProductUpdate();

    const onSubmit = async (values: ProductFormState) => {
        return productUpdateMutation.mutateAsync(
            {
                product: {
                    supplierProductGroup: values.supplierProductGroup,
                    name: values.name,
                    group: values.group,
                    packTypeDefault: values.packTypeDefault,
                    unitDefault: values.unitDefault as ProductUnit,
                    type: ProductContext.LUBES,
                },
                id: props.product.id,
            },
            {
                onSuccess: () => {
                    addToast(translate('productsOverview.updateSuccessToast'));
                    pop();
                },
                onError: () => {
                    addErrorToast(translate('productsOverview.updateErrorToast'));
                },
            }
        );
    };

    const onEditButtonClick = () => {
        push({
            content: (
                <ProductForm
                    onSubmit={onSubmit}
                    title={translate('productsOverview.updateDrawerTitle')}
                    initialState={{
                        supplierProductGroup: props.product.supplierProductGroup,
                        name: props.product.name,
                        group: props.product.group,
                        packTypeDefault: props.product.packTypeDefault,
                        unitDefault: props.product.unitDefault,
                    }}
                />
            ),
        });
    };

    return (
        <IconButton
            emphasis="transparent"
            icon={IconPencil}
            onClick={onEditButtonClick}
            tooltipLabel={translate('productsOverview.updateTooltip')}
        />
    );
};
