import { useNavigate } from '@tanstack/react-router';

import { Button } from '../../cdl/Button/Button';
import { EmptyPagePlaceholder } from '../../common/EmptyPagePlaceholder/EmptyPagePlaceholder';
import { translate } from '../../common/helpers/translate.helper';
import { IconGameplan } from '../../common/icons/cdl/Gameplan';
import { Box } from '../../common/ui/Box';
import { theme } from '../../common/ui/theme';

export const NoBunkerPlans = () => {
    const navigate = useNavigate();

    return (
        <EmptyPagePlaceholder
            title={translate('bunkerPlan.nothingPlanned')}
            description={translate('bunkerPlan.createPlanFirst')}
            icon={<IconGameplan width={24} height={24} color={theme.colors.foreground.subtle} />}
            action={
                <Button emphasis="high" onClick={() => navigate({ to: '/fuel/planning/demand' })}>
                    <Box display="flex" flexDirection="row" alignItems="center" columnGap={3}>
                        {translate('bunkerPlan.viewVessels')}
                    </Box>
                </Button>
            }
        />
    );
};
