import { createFileRoute } from '@tanstack/react-router';

import { getOfferById } from '../common/api/clients/gateway.api';
import { getRole } from '../common/hooks/useRole';
import { LubesOfferDetailPage } from '../offer/detail/lubes/LubesOfferDetailPage';

export const Route = createFileRoute('/_app/_lubes/offer/$id')({
    async loader(ctx) {
        getOfferById(ctx.params.id).then((offer) => {
            getRole().updateContext(offer.type);
        });
    },
    component: LubesOfferDetailPage,
});
