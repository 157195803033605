import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { Text } from '../../cdl/Text/Text';
import { CenteredPageError } from '../../common/Error/CenteredPageError';
import { formatDateTime } from '../../common/helpers/formatDateTime.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';

import { BunkerSolutionDetails } from './components/BunkerSolutionDetails/BunkerSolutionDetails';
import { BunkerSolutionMap } from './components/BunkerSolutionMap';
import { BunkerSolutionsTable } from './components/BunkerSolutionsTable/BunkerSolutionsTable';
import { useBunkerMetricBunkerCase } from './hooks/useBunkerMetricBunkerCase';

export const PrePlanningPage = () => {
    useDocumentTitle(translate('prePlanning.pageTitle'));
    const navigate = useNavigate();
    const {
        vesselId,
        caseNumber,
        solutionNumber: solutionNumberParam,
    } = useAngularRouterLocation<{
        vesselId: string;
        caseNumber: string;
        solutionNumber: string;
    }>();
    const [solutionNumber, setSolutionNumber] = useState(solutionNumberParam);

    const bunkerMetricBunkerCaseQuery = useBunkerMetricBunkerCase({
        vesselId,
        caseNumber,
    });

    if (bunkerMetricBunkerCaseQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (bunkerMetricBunkerCaseQuery.isError) {
        return (
            <Page>
                <CenteredPageError />
            </Page>
        );
    }

    return (
        <Page>
            <Box marginBottom={5} display="flex" gap={4}>
                <Text as="h1" variant="headline">
                    {translate('prePlanning.pageTitle')}
                </Text>
                <Text variant="headline" color="foreground.muted">
                    &middot;
                </Text>
                <Text variant="headline" color="foreground.subtle">
                    {bunkerMetricBunkerCaseQuery.data.vessel.name}
                </Text>
            </Box>
            <Box>
                <Box marginBottom={5}>
                    <Box display="flex" justifyContent="space-between" alignItems="top">
                        <Text as="h2" variant="title">
                            {translate('prePlanning.recommendationsTitle')}
                        </Text>
                        <Text variant="action" color="foreground.muted">
                            {translate('prePlanning.generatedByBunkerMetricAt', {
                                establishedDate: formatDateTime({
                                    date: bunkerMetricBunkerCaseQuery.data.establishedDate,
                                }),
                            })}
                        </Text>
                    </Box>
                    <Text variant="small" color="foreground.muted">
                        {translate('prePlanning.recommendationsSubtitle')}
                    </Text>
                </Box>

                <BunkerSolutionsTable
                    vesselId={vesselId}
                    caseNumber={caseNumber}
                    solutionNumber={solutionNumber}
                    solutions={bunkerMetricBunkerCaseQuery.data.solutions}
                    onSolutionNumberSelect={(selectedSolutionNumber) => {
                        setSolutionNumber(selectedSolutionNumber);
                        navigate({
                            to: '/fuel/planning/solutions/$vesselId/$caseNumber/$solutionNumber',
                            params: {
                                vesselId,
                                caseNumber,
                                solutionNumber: selectedSolutionNumber,
                            },
                        });
                    }}
                />
            </Box>
            <Box marginTop={8}>
                <BunkerSolutionDetails
                    vesselId={vesselId}
                    caseNumber={caseNumber}
                    solutionNumber={solutionNumber}
                    rank={
                        bunkerMetricBunkerCaseQuery.data.solutions.find((it) => it.solutionNumber === solutionNumber)!!
                            .rank
                    }
                />
            </Box>

            <Box marginTop={6}>
                <BunkerSolutionMap vesselId={vesselId} caseNumber={caseNumber} solutionNumber={solutionNumber} />
            </Box>
        </Page>
    );
};
