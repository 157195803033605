import invariant from 'tiny-invariant';

import { JWTHelper } from '../../common/JwtHelper';
import { initialize } from '../../common/launchDarkly/launchDarklyClient';
import { AblyService } from '../../common/services/AblyService';
import { router } from '../../router';

import { getCustomerGroup, getCustomerCompanies } from './customer.api';
import { getUser, login, renew } from './login.api';
import { RoleService } from './RoleService';
import { SessionStorageService } from './SessionStorageService';
import { getSupplierCompanies, getSupplierGroup } from './supplier.api';

let instance = null;

export class SessionService {
    static getInstance() {
        if (!instance) {
            instance = new SessionService();
        }
        return instance;
    }

    async loadRoleInformation() {
        invariant(this.isAuthenticated(), 'called loadRoleInformation with an unauthenticated user');

        RoleService.getInstance().create(this.getToken());
        const user = await getUser();
        const role = RoleService.getInstance().addUser(user);

        if (!role.isAdmin()) {
            await this.enrichRoleWithGroup(role, user);
            await this.enrichRoleWithCompanies(role);
        }

        try {
            await initialize(role);
            AblyService.connect();
        } catch {
            // Fail silently in case LaunchDarkly has issues.
        }

        this.propagateAuthenticated();
        return role;
    }

    async authenticate(credentials) {
        await this.saveTokenAndLoadUserData(login(credentials.username, credentials.password));
    }

    async renewSession() {
        RoleService.getInstance().reset();

        return this.saveTokenAndLoadUserData(renew());
    }

    async enrichRoleWithGroup(role, user) {
        const groupDetails = role.isCustomer()
            ? await getCustomerGroup(user.groupId)
            : await getSupplierGroup(user.groupId);
        RoleService.getInstance().addGroup(groupDetails);
    }

    async enrichRoleWithCompanies(role) {
        const companies = role.isCustomer() ? await getCustomerCompanies() : await getSupplierCompanies();
        RoleService.getInstance().addCompanies(companies);
    }

    async saveTokenAndLoadUserData(tokenPromise) {
        try {
            const response = await tokenPromise;
            const token = response.data.accessToken;
            SessionStorageService.saveToken(token);

            return this.loadRoleInformation();
        } catch (error) {
            return this.handleAuthenticateError(error);
        }
    }

    propagateAuthenticated() {
        RoleService.getInstance().authenticated();
    }

    handleAuthenticateError(error) {
        SessionStorageService.resetToken();
        return Promise.reject(error);
    }

    getToken() {
        if (SessionStorageService.getToken()) {
            return JWTHelper.decode(SessionStorageService.getToken());
        }
        return [];
    }

    isAuthenticated() {
        if (!SessionStorageService.getToken() || JWTHelper.hasTokenExpired(SessionStorageService.getToken())) {
            this.logout();
        }

        return SessionStorageService.getToken() !== null;
    }

    logout() {
        router.navigate({ to: '/logout' });
    }
}
