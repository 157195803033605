import { VesselMessage } from '../../../../types/VesselMessage';
import { VesselUpdateRequestMessage } from '../../../../types/VesselUpdateRequestMessage';
import { useDrawer } from '../../../cdl/Drawer/useDrawer.hook';
import { IconButton } from '../../../cdl/IconButton/IconButton';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { IconPencil } from '../../../common/icons/cdl/Pencil';
import { Section } from '../../../common/Section/Section';
import { EditVesselForm } from '../EditVessel/EditVesselForm';
import { useVesselMutation } from '../hooks/useVesselMutation';

import { FuelVesselDetails } from './FuelVesselDetails';

interface FuelInfoSectionProps {
    vessel: VesselMessage;
}

export const FuelVesselDetailsSection = ({ vessel }: FuelInfoSectionProps) => {
    const { pop, push } = useDrawer();
    const role = useRole();

    const { mutate: mutateVessel } = useVesselMutation(vessel.id);

    const parseEditClose = (updatedVessel: VesselUpdateRequestMessage) => {
        mutateVessel({
            vesselId: vessel.id,
            vessel: updatedVessel,
        });

        pop();
    };

    const onEditClick = () => {
        push({
            content: <EditVesselForm onSubmit={parseEditClose} vessel={vessel} />,
        });
    };

    return (
        <Section
            title={translate('vessel.info')}
            actions={
                role.hasWriteRights(vessel.customerId) ? (
                    <IconButton emphasis="medium" icon={IconPencil} onClick={onEditClick} />
                ) : null
            }
        >
            <FuelVesselDetails vessel={vessel} />
        </Section>
    );
};
