import { useParams } from '@tanstack/react-router';
import { Form, useFormikContext } from 'formik';
import moment from 'moment/moment';
import { useEffect, useState } from 'react';

import { VesselMessage } from '../../../../../types/VesselMessage';
import { FormikAgentSelectFlow } from '../../../../agent/AgentSelectFlow/FormikAgentSelectFlow';
import { Button } from '../../../../cdl/Button/Button';
import { FormikInput } from '../../../../cdl/Input/FormikInput';
import { Text } from '../../../../cdl/Text/Text';
import { FormikTextArea } from '../../../../cdl/TextArea/FormikTextArea';
import { useToasts } from '../../../../cdl/Toast/useToasts';
import { Divider } from '../../../../common/Divider/Divider';
import { FormikDatePicker } from '../../../../common/form-elements/formik/FormikDatePicker';
import { FormikDebug } from '../../../../common/form-elements/formik/FormikDebug';
import { useFormikDirtyWarning } from '../../../../common/form-elements/formik/useFormikDirtyWarning';
import { FormikLubesProductTable } from '../../../../common/FormikProductTable/lubes/FormikLubesProductTable';
import { formatDateTime } from '../../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../../../common/hooks/useAngularRouterLocation';
import { useRole } from '../../../../common/hooks/useRole';
import { useSchedules } from '../../../../common/hooks/useSchedules';
import { OverlayHeader } from '../../../../common/Overlay/OverlayHeader';
import { Box } from '../../../../common/ui/Box';
import { FormikPortSelectWithSchedules } from '../../../../port/portSelect/FormikPortDetailSelectWithSchedule';
import { OrderVesselInformation } from '../../../create/OrderVesselInformation';
import { OrderState } from '../../../model/OrderModel';
import { useDraftCreate } from '../hooks/useDraftCreate';
import { useEnquiryUpdate } from '../hooks/useEnquiryUpdate';
import { FormikCreateEnquiryValues, LubesCreateEnquiryRouterParams } from '../LubesCreateEnquiryPage';
import { buildOrderRequest } from '../util/buildOrderRequest';
import { isDateDeliveryInPast } from '../util/isDateDeliveryInPast';

import { CreationComment } from './CreationComment';
import { FormikCompareOverlay } from './FormikCompareOverlay';
import { FormikPackagePrices } from './FormikPackagePrices';
import { FormikSupplierSelect } from './FormikSupplierSelect';
import { FormikVesselSelect } from './FormikVesselSelect';

interface LubesCreateEnquiryFormProps {
    onCloseClick: (dirty: boolean) => void;
}

export const FormikLubesCreateEnquiryForm = ({ onCloseClick }: LubesCreateEnquiryFormProps) => {
    useFormikDirtyWarning();
    const [selectedVessel, setSelectedVessel] = useState<VesselMessage>();
    const useDraftCreateMutation = useDraftCreate();
    const useEnquiryUpdateMutation = useEnquiryUpdate();
    const { addToast, addErrorToast } = useToasts();
    const { setFieldError, values, isSubmitting, setFieldValue, dirty } = useFormikContext<FormikCreateEnquiryValues>();
    const { schedules: schedule } = useSchedules(selectedVessel?.imo);
    const { order: orderFromState, vessel: vesselFromState } =
        useAngularRouterLocation<LubesCreateEnquiryRouterParams>();
    const role = useRole();

    const { id } = useParams({ strict: false });

    const isDraftEnquiry = !!id;

    useEffect(() => {
        setSelectedVessel(orderFromState?.vessel || vesselFromState);
    }, [orderFromState?.vessel, vesselFromState]);

    const handleDraftSave = async (values: FormikCreateEnquiryValues) => {
        if (isDateDeliveryInPast(values.dateDelivery)) {
            setFieldError('dateDelivery', translate('errormessage.isInPast'));
        } else {
            if (id) {
                await useEnquiryUpdateMutation.mutateAsync(
                    { order: buildOrderRequest(values, OrderState.DRAFT), orderId: id },
                    {
                        onSuccess: () => {
                            addToast(translate('order.successDraftToast'));
                        },
                        onError: () => {
                            addErrorToast(translate('order.errorDraftToast'));
                        },
                    }
                );
            } else {
                await useDraftCreateMutation.mutateAsync(buildOrderRequest(values, OrderState.DRAFT));
            }
        }
    };

    return (
        <Form>
            <OverlayHeader
                title={isDraftEnquiry ? translate('order.createNewDraft') : translate('order.createnew')}
                onClose={() => onCloseClick(dirty)}
                trailingVisual={
                    <Box display="flex" justifyContent="right" alignItems="center" gap={4}>
                        {values.customerId && !isDraftEnquiry ? (
                            <Button
                                type="button"
                                isLoading={useDraftCreateMutation.isPending}
                                onClick={() => handleDraftSave(values)}
                            >
                                {translate('order.saveAsDraftButton')}
                            </Button>
                        ) : null}

                        {isDraftEnquiry ? (
                            <Button
                                type="button"
                                isLoading={useEnquiryUpdateMutation.isPending}
                                onClick={() => handleDraftSave(values)}
                            >
                                {translate('order.saveDraftButton')}
                            </Button>
                        ) : null}

                        <Button type="submit" emphasis="high" isLoading={isSubmitting}>
                            {translate('order.btncreate')}
                        </Button>
                    </Box>
                }
            >
                <Box padding={7} display="flex" flexDirection="column" gap={7}>
                    {values.creationComment ? (
                        <Box width="50%" paddingRight="20px">
                            <CreationComment text={values.creationComment} />
                        </Box>
                    ) : null}

                    <div>
                        <Text variant="title">{translate('order.coreData')}</Text>
                        <Text variant="body" color="foreground.muted" as="p">
                            {translate('order.coreDataSubText')}
                        </Text>

                        <Divider marginTop={5} marginBottom={7} />
                        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={8} marginBottom={6}>
                            <Box display="flex" gap={5} flexDirection="column">
                                <FormikVesselSelect
                                    name="vesselId"
                                    label={translate('global.coreData.vessel')}
                                    onVesselChange={(vessel) => {
                                        setSelectedVessel(vessel);
                                        setFieldValue('customerId', vessel?.customerId);
                                    }}
                                />

                                <FormikPortSelectWithSchedules
                                    name="portId"
                                    schedule={schedule}
                                    label={translate('order.port')}
                                    onSelect={(port, scheduleRank) => {
                                        setFieldValue('agentId', undefined, false);

                                        const matchingSchedule = schedule.find(
                                            (schedule) =>
                                                schedule.port?.id === port.id && schedule.rank === scheduleRank
                                        );

                                        if (matchingSchedule) {
                                            setFieldValue(
                                                'dateDelivery',
                                                // Set dateDelivery to the schedules current date, but at the start of the day in UTC.
                                                // With the format we remove the timezone of the schedule.
                                                moment
                                                    .utc(
                                                        formatDateTime({
                                                            date: matchingSchedule.eta,
                                                            timeZoneId: matchingSchedule.timeZoneId,
                                                        })
                                                    )
                                                    .startOf('day')
                                                    .toDate()
                                                    .getTime(),
                                                false
                                            );

                                            if (matchingSchedule.agentIds?.length === 1) {
                                                setFieldValue('agentId', matchingSchedule.agentIds[0], false);
                                            }
                                        }
                                    }}
                                />

                                <FormikDatePicker name="dateDelivery" label={translate('order.dateDelivery')} />

                                {values.customerId && (values.portId || isDraftEnquiry) ? (
                                    <FormikInput
                                        label={translate('order.buyerReferenceOptional')}
                                        name="buyerReference"
                                    />
                                ) : null}

                                {values.customerId && values.portId ? (
                                    <FormikAgentSelectFlow
                                        name="agentId"
                                        label={translate('order.agentOptional')}
                                        customerId={selectedVessel?.customerId}
                                        portId={values.portId}
                                    />
                                ) : null}
                                <FormikTextArea
                                    name="comment"
                                    label={translate('order.commentForSupplier')}
                                    caption={translate('order.commentForSupplierCaption')}
                                    rows={4}
                                />
                            </Box>

                            <div>
                                <OrderVesselInformation vesselId={values.vesselId} />
                            </div>
                        </Box>
                    </div>

                    <div>
                        <Text variant="title">{translate('order.products')}</Text>
                        <Text variant="body" color="foreground.muted" as="p">
                            {translate('order.productssubtext')}
                        </Text>

                        <Divider marginTop={5} marginBottom={7} />
                        <Box>
                            <FormikLubesProductTable
                                config={{
                                    initialItems: [],
                                    editable: true,
                                    showPrices: false,
                                    editSurcharges: false,
                                    editPrices: false,
                                }}
                            />
                        </Box>
                    </div>

                    <Box
                        display="grid"
                        gridTemplateColumns="1fr 1fr"
                        gap={5}
                        alignItems="start"
                        marginTop="-32px"
                        gridTemplateAreas="'compare prices'"
                    >
                        <Box gridArea="compare">
                            {!role.usesCloselinkLite() ? <FormikCompareOverlay schedule={schedule} /> : null}
                        </Box>

                        <Box gridArea="prices">
                            <FormikPackagePrices />
                        </Box>
                    </Box>

                    <div>
                        <Text variant="title">{translate('order.supplier')}</Text>
                        <Text variant="body" color="foreground.muted" as="p">
                            {translate('order.suppliersubtext')}
                        </Text>

                        <Divider marginTop={5} marginBottom={7} />
                        <Box>
                            {isDraftEnquiry ? (
                                selectedVessel ? (
                                    <FormikSupplierSelect
                                        portId={values.portId}
                                        customerId={selectedVessel?.customerId}
                                        defaultSupplierId={selectedVessel?.defaultSupplierId}
                                        directOrderAllowed={selectedVessel?.directOrderAllowed}
                                    />
                                ) : null
                            ) : (
                                <FormikSupplierSelect
                                    portId={values.portId}
                                    customerId={selectedVessel?.customerId}
                                    defaultSupplierId={selectedVessel?.defaultSupplierId}
                                    directOrderAllowed={selectedVessel?.directOrderAllowed}
                                />
                            )}
                        </Box>
                    </div>
                </Box>

                <div id="closelink-portal-view" />
            </OverlayHeader>
            <FormikDebug />
        </Form>
    );
};
