import { Key, ReactNode, useMemo } from 'react';
import { components, ControlProps, default as Select } from 'react-select';
import { AsyncProps as ReactSelectAsyncProps, default as AsyncSelect } from 'react-select/async';

import { Caption, CaptionProps } from '../Caption/Caption';
import { CustomControl } from '../Select/components/CustomControl';
import { CustomDropdownIndicator } from '../Select/components/CustomDropdownIndicator';
import { CustomOption } from '../Select/components/CustomOption';
import { CustomPlaceholder } from '../Select/components/CustomPlaceholder';
import { CustomSingleValue } from '../Select/components/CustomSingleValue';

import { CustomInput } from './components/CustomInput';
import { CustomLoadingIndicator } from './components/CustomLoadingIndicator';
import { CustomLoadingMessage } from './components/CustomLoadingMessage';
import { CustomMenu } from './components/CustomMenu';
import { CustomMenuList } from './components/CustomMenuList';
import { CustomMultiValueContainer } from './components/CustomMultiValueContainer';
import { CustomMultiValueLabel } from './components/CustomMultiValueLabel';
import { CustomMultiValueRemove } from './components/CustomMultiValueRemove';
import { CustomNoOptionsMessage } from './components/CustomNoOptionsMessage';
import { CustomValueContainer } from './components/CustomValueContainer';

export interface DefaultOption {
    value: string;
    label: string;
}

export interface ComboBoxProps<Option, IsMulti extends boolean>
    extends Pick<
        ReactSelectAsyncProps<Option, IsMulti, any>,
        'value' | 'onChange' | 'loadOptions' | 'defaultOptions' | 'cacheOptions' | 'onBlur' | 'options' | 'isLoading'
    > {
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    caption?: ReactNode;
    captionIcon?: CaptionProps['icon'];
    isMulti?: IsMulti;
    positive?: boolean;
    negative?: boolean;
    isAsync?: boolean;
    markAsRequired?: boolean;
    rerenderValue?: Key;
    dropdownWidth?: string;
    backspaceRemovesValue?: ReactSelectAsyncProps<Option, IsMulti, any>['backspaceRemovesValue'];
    overrides?: ReactSelectAsyncProps<Option, IsMulti, any>['components'];
}

export function ComboBox<Option = DefaultOption, IsMulti extends boolean = false>({
    label,
    caption,
    captionIcon,
    placeholder = '',
    defaultOptions = true,
    isLoading = false,
    cacheOptions = true,
    isAsync = true,
    positive = false,
    negative = false,
    markAsRequired = false,
    disabled = false,
    ...rest
}: ComboBoxProps<Option, IsMulti>) {
    const SelectComponent = isAsync ? AsyncSelect<Option, IsMulti> : Select<Option, IsMulti>;

    const CustomControlMemoized = useMemo(() => {
        return function CustomControlMemoizedFn({ children, ...rest }: ControlProps<Option, IsMulti>) {
            return (
                <CustomControl
                    {...rest}
                    label={label}
                    $positive={positive}
                    $negative={negative}
                    markAsRequired={markAsRequired}
                >
                    {children}
                </CustomControl>
            );
        };
    }, [label, positive, negative, markAsRequired]);

    return (
        <div>
            <SelectComponent
                backspaceRemovesValue={rest.backspaceRemovesValue}
                key={rest.rerenderValue}
                defaultOptions={defaultOptions}
                cacheOptions={cacheOptions}
                loadOptions={rest.loadOptions}
                placeholder={placeholder}
                isSearchable
                options={rest.options}
                isMulti={rest.isMulti}
                isLoading={isLoading}
                value={rest.value}
                onChange={rest.onChange}
                hideSelectedOptions={false}
                isDisabled={disabled}
                components={{
                    ...components,
                    Option: CustomOption as any,
                    Input: CustomInput as any,
                    Menu: (props) => <CustomMenu<any> {...props} dropdownWidth={rest.dropdownWidth} />,
                    MenuList: CustomMenuList as any,
                    Control: CustomControlMemoized as any,
                    SingleValue: CustomSingleValue as any,
                    IndicatorSeparator: () => null,
                    ClearIndicator: () => null,
                    DropdownIndicator: CustomDropdownIndicator as any,
                    Placeholder: CustomPlaceholder as any,
                    LoadingMessage: CustomLoadingMessage as any,
                    LoadingIndicator: CustomLoadingIndicator as any,
                    NoOptionsMessage: CustomNoOptionsMessage as any,
                    ValueContainer: CustomValueContainer as any,
                    MultiValueContainer: CustomMultiValueContainer as any,
                    MultiValueLabel: CustomMultiValueLabel as any,
                    MultiValueRemove: CustomMultiValueRemove as any,
                    ...rest.overrides,
                }}
                onBlur={rest.onBlur}
            />
            {caption ? (
                <Caption $negative={negative} icon={captionIcon}>
                    {caption}
                </Caption>
            ) : null}
        </div>
    );
}
