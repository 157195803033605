import { EnergyContentMessage } from '../../../../types/EnergyContentMessage';
import { SamplekitMessage } from '../../../../types/SamplekitMessage';
import { OrderState } from '../../../order/model/OrderModel';
import { authorizedAxiosInstance } from '../instances/AuthorizedAxiosInstance';

type MillisecondsSinceEpoch = number;

interface CreateOrderItemRequest {
    productId?: string;
    packType: string;
    unitSize?: number;
    units?: number;
    unit: string;
}

interface CreateOrderRequest {
    vesselId: string;
    customerId: string;
    portId?: string;
    buyerReference?: string;
    agentId?: string;
    comment?: string;
    assignedSupplierIds?: string[];
    receiverSupplierIds?: string[];
    samplekits?: SamplekitMessage[];
    spot?: boolean;
    state?: OrderState;
    testOrder?: boolean;
}

type LubesCreateOrderItemRequest = CreateOrderItemRequest & {
    volume?: number;
    deleted: boolean;
};

type FuelCreateOrderItemRequest = CreateOrderItemRequest & {
    productName?: string;
    isoSpec?: string;
    energyContent?: EnergyContentMessage;
    sulphurContent?: number;
    group?: string;
};

export type LubesCreateOrderRequest = CreateOrderRequest & {
    dateDelivery?: MillisecondsSinceEpoch;
    items?: LubesCreateOrderItemRequest[];
};

export type FuelCreateOrderRequest = CreateOrderRequest & {
    items?: FuelCreateOrderItemRequest[];
    type: string;
    contractId: string;
    eta: MillisecondsSinceEpoch;
    etd: MillisecondsSinceEpoch;
    validityTime: number;
    fileIds: string[];
};

export interface CreateOrderResponse extends LubesCreateOrderRequest {
    id: string;
}

type LubesUpdateOrderRequest = LubesCreateOrderRequest & {
    cancelReason?: string;
};

type FuelUpdateOrderRequest = FuelCreateOrderRequest;

export const createOrder = async (
    order: LubesCreateOrderRequest | FuelCreateOrderRequest
): Promise<CreateOrderResponse> => {
    const response = await authorizedAxiosInstance.post('/v1/order', order);

    return response.data;
};

interface UpdateOrderParams {
    orderId: string;
    order: LubesUpdateOrderRequest | FuelUpdateOrderRequest;
}

export const updateOrder = async ({ orderId, order }: UpdateOrderParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/order/${orderId}`, order);

    return response.data;
};
export const validateOrder = async (
    order: unknown
): Promise<{ validationErrors: { path: string; code: string; message?: string }[] }> => {
    const response = await authorizedAxiosInstance.post('/v1/order/validation', order);

    return response.data;
};

interface CreateMessageParams {
    offerId: string;
    message: string;
}

export const createMessage = async ({ offerId, message }: CreateMessageParams): Promise<any> => {
    const response = await authorizedAxiosInstance.post(`/v1/offer/${offerId}/message`, { message });

    return response.data;
};

export interface MarkOrderReadParams {
    orderId: string;
    read: boolean;
}

export const markOrderRead = async ({ orderId, read }: MarkOrderReadParams): Promise<void> => {
    await authorizedAxiosInstance.put(`/v1/order/${orderId}/read/${read}`);
};
export const redispatchOrder = async (orderId: string): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/order/${orderId}/redispatch`);

    return response.data;
};
export const deleteOrder = async (orderId: string): Promise<void> => {
    await authorizedAxiosInstance.delete(`/v1/order/${orderId}`);
};

interface MarkOfferReadParams {
    offerId: string;
    read: boolean;
}

export const markOfferRead = async ({ offerId, read }: MarkOfferReadParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/offer/${offerId}/read/${read}`);

    return response.data;
};

interface UpdateOfferParams {
    offerId: string;
    offer: unknown;
}

export const updateOffer = async ({ offerId, offer }: UpdateOfferParams): Promise<any> => {
    const response = await authorizedAxiosInstance.put(`/v1/offer/${offerId}`, offer);

    return response.data;
};
