import { useNavigate } from '@tanstack/react-router';
import { useRef, useState } from 'react';

import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { translateSulphurContent } from '../../../common/helpers/translateSulphurContent';
import { useRole } from '../../../common/hooks/useRole';
import { IconList } from '../../../common/icons/cdl/List';
import { ListingWithMore } from '../../../common/ListingWithMore/ListingWithMore';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { ItemModel } from '../../../common/models/ItemModel';
import { Section } from '../../../common/Section/Section';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { CircleValidity } from '../../../common/Validity/CircleValidity';
import { OfferModel } from '../../../offer/model/OfferModel';
import { useOfferPagination } from '../../../offer/useOfferPagination';
import { Port } from '../../../port/Port';

export const FuelCustomerOfferTable = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState(0);

    const role = useRole();
    const navigate = useNavigate();

    const offerPaginationQuery = useOfferPagination({
        page,
        types: [ProductContext.FUEL],
        states: ['QUOTED'],
        sortField: 'quoteValidUntil',
        sortDirection: 'ASC',
    });

    if (offerPaginationQuery.isPending) {
        return (
            <Section title={translate('offer.openOffers')}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (offerPaginationQuery.isError) {
        return (
            <Section title={translate('offer.openOffers')}>
                <Error />
            </Section>
        );
    }

    if (!offerPaginationQuery.data?.content.length) {
        return (
            <Section title={translate('offer.openOffers')}>
                <TableEmptyState Icon={IconList} text={translate('order.emptylist')} />
            </Section>
        );
    }

    const onRowClick = (offer: OfferModel) => {
        navigate({ to: '/fuel/order/$id', params: { id: offer.orderId } });
    };

    return (
        <div ref={wrapperRef}>
            <Section title={translate('offer.openOffers')}>
                <TableBuilder<OfferModel>
                    data={offerPaginationQuery.data.content}
                    onRowSelect={onRowClick}
                    isLoading={offerPaginationQuery.isPlaceholderData && offerPaginationQuery.isFetching}
                >
                    {!role.isOneCompanyUser(ProductContext.FUEL) && (
                        <TableBuilderColumn<OfferModel> header={translate('order.company')} width="15%">
                            {(offer) => formatCompanyName({ company: offer.customer }) || '-'}
                        </TableBuilderColumn>
                    )}

                    <TableBuilderColumn<OfferModel> header={translate('order.vessel')} width="15%">
                        {(offer) => formatVessel({ vessel: offer.vessel, short: true })}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OfferModel> header={translate('order.port')} width="15%">
                        {(offer) => {
                            if (!offer.port) {
                                return null;
                            }
                            return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
                        }}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OfferModel> header={translate('order.supplier')} width="15%">
                        {(offer) => formatCompanyName({ company: offer.supplier }) || null}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OfferModel> header={translate('order.fuelProducts')} width="20%">
                        {(offer) => (
                            <ListingWithMore showCount={1}>
                                {offer.items.map((item: ItemModel) => {
                                    const translatedSulphur = translateSulphurContent(item.product?.sulphurContent);
                                    return `${item.product?.name} ${translatedSulphur}`;
                                })}
                            </ListingWithMore>
                        )}
                    </TableBuilderColumn>

                    <TableBuilderColumn<OfferModel> header={translate('order.fuelPrices')} width="10%">
                        {(offer) => {
                            if (!offer.items.some((item: ItemModel) => item.price)) {
                                return null;
                            }
                            return (
                                <ListingWithMore showCount={1}>
                                    {offer.items
                                        .filter((item: ItemModel) => item.price)
                                        .map((item: ItemModel) =>
                                            formatMoney({
                                                value: item.price!.value,
                                                currency: item.price!.currency,
                                                maximumFractionDigits: 3,
                                                minimumFractionDigits: 2,
                                            })
                                        )}
                                </ListingWithMore>
                            );
                        }}
                    </TableBuilderColumn>
                    <TableBuilderColumn<OfferModel> header={translate('order.quoteValidity')} width="15%">
                        {(offer) => {
                            if (!offer.quoteValidUntil || !offer.quoteValidityTime) {
                                return null;
                            }
                            return (
                                <CircleValidity
                                    validityTime={offer.quoteValidityTime}
                                    validUntil={offer.quoteValidUntil}
                                    background="transparent"
                                />
                            );
                        }}
                    </TableBuilderColumn>
                </TableBuilder>

                <CenteredPagination
                    pageTotal={offerPaginationQuery.pageTotal}
                    currentPage={page}
                    onPageChange={(page) => {
                        setPage(page);
                        if (wrapperRef.current) {
                            window.scrollTo(0, wrapperRef.current.offsetTop);
                        }
                    }}
                />
            </Section>
        </div>
    );
};
