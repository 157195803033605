import { OnChangeValue } from 'react-select';

import { NoFilterResults } from '../../cdl/Filter/NoFilterResults/NoFilterResults';
import { Text } from '../../cdl/Text/Text';
import { EmptyPagePlaceholder } from '../../common/EmptyPagePlaceholder/EmptyPagePlaceholder';
import { CenteredPageError } from '../../common/Error/CenteredPageError';
import { CustomerGroupSelect } from '../../common/form-elements/CustomerGroupSelect/CustomerGroupSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { IconGameplan } from '../../common/icons/cdl/Gameplan';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';
import { theme } from '../../common/ui/theme';
import { Route } from '../../routes/_app._lubes.demand';
import { DemandAccordion } from '../components/DemandAccordion';
import { DemandLubesReachBanner } from '../components/DemandLubesReachBanner';
import { DemandSortingSelect } from '../components/DemandSortingSelect';
import { VesselDemandFilter } from '../components/VesselDemandFilter';
import { useLubesDemandInformation } from '../hooks/useLubesDemandInformation';

const DemandOverviewLoading = ({ showEmptyPagePlaceholder }: { showEmptyPagePlaceholder: boolean }) => {
    if (showEmptyPagePlaceholder) {
        return (
            <Box display="flex" justifyContent="center" paddingTop="88px">
                <EmptyPagePlaceholder
                    title={translate('demand.emptyPageAdmin.title')}
                    description={translate('demand.emptyPageAdmin.subtitle')}
                    icon={<IconGameplan height={24} width={24} color={theme.colors.foreground.subtle} />}
                />
            </Box>
        );
    }

    return <LoadingIndicator variant="page" />;
};

export const DemandOverviewPage = () => {
    useDocumentTitle(translate('demand.demandOverview'));
    const role = useRole();
    const search = Route.useSearch();
    const navigate = Route.useNavigate();

    const demandInformationQuery = useLubesDemandInformation(search.sortingKey, search.groupId);

    const onGroupChange = (option: OnChangeValue<string, false>) => {
        if (option !== null) {
            navigate({ search: (previousSearch) => ({ ...previousSearch, groupId: option }) });
        }
    };

    return (
        <Page>
            <Box display="flex" justifyContent="space-between" columnGap={6} marginBottom={5}>
                <Text
                    as="h1"
                    variant="headline"
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {translate('demand.demandOverview')}
                </Text>

                <Box display="flex" justifyContent="space-between" columnGap={4}>
                    {role.isAdmin() ? (
                        <Box width="300px">
                            <CustomerGroupSelect
                                isMulti={false}
                                onChange={onGroupChange}
                                value={search.groupId}
                                placeholder={translate('demand.groupSelectPlaceholder')}
                                dropdownWidth="520px"
                            />
                        </Box>
                    ) : null}
                    <Box width="300px">
                        <DemandSortingSelect
                            value={search.sortingKey}
                            onChange={(option) => {
                                if (option) {
                                    navigate({
                                        search: (previousSearch) => ({ ...previousSearch, sortingKey: option.value }),
                                    });
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Box>

            {demandInformationQuery.isPending ? (
                <DemandOverviewLoading showEmptyPagePlaceholder={role.isAdmin() && !search.groupId} />
            ) : null}
            {demandInformationQuery.isError ? <CenteredPageError /> : null}

            {demandInformationQuery.isSuccess ? (
                <>
                    {demandInformationQuery.data.vessels.every((vessel) => !vessel.categories.length) &&
                    !role.isAdmin() ? (
                        <DemandLubesReachBanner />
                    ) : null}

                    <Box marginTop={5}>
                        <VesselDemandFilter allEntries={demandInformationQuery.data.vessels} groupId={search.groupId}>
                            {(filteredItems) => {
                                if (filteredItems.length === 0) {
                                    return <NoFilterResults />;
                                }

                                return (
                                    <Box display="grid" rowGap={5}>
                                        {filteredItems.map((vessel) => {
                                            if (!vessel.categories.length) {
                                                return (
                                                    <>
                                                        <DemandAccordion key={vessel.id} vessel={vessel} disabled />
                                                    </>
                                                );
                                            }

                                            return <DemandAccordion key={vessel.id} vessel={vessel} />;
                                        })}
                                    </Box>
                                );
                            }}
                        </VesselDemandFilter>
                    </Box>
                </>
            ) : null}
        </Page>
    );
};
