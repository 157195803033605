import React, { ReactElement, ReactNode } from 'react';

import { Text } from '../Text/Text';

import { DefaultCell } from './components/DefaultCell';
import { InlineCell } from './components/InlineCell';
import { StyledDefaultHeaderCell } from './components/StyledDefaultHeaderCell';
import { StyledDefaultTableBodyRow } from './components/StyledDefaultTableBodyRow';
import { StyledInlineHeaderCell } from './components/StyledInlineHeaderCell';
import { StyledInlineTableBodyRow } from './components/StyledInlineTableBodyRow';
import { StyledTable } from './components/StyledTable';
import { TableBuilderColumnProps } from './TableBuilderColumn';

interface TableBuilderDefaultVariantProps<T> {
    variant?: 'default';
    data?: T[];
    activeIndex?: number;
    onRowSelect?: (row: T, index: number) => void;
    alternateRowColors?: boolean;
    children: ReactNode;
    isLoading?: boolean;
}

interface TableBuilderInlineVariantProps<T> {
    variant: 'inline';
    data?: T[];
    activeIndex?: never;
    onRowSelect?: never;
    alternateRowColors?: never;
    children: ReactNode;
}

export type TableBuilderProps<T> = TableBuilderDefaultVariantProps<T> | TableBuilderInlineVariantProps<T>;

export function TableBuilder<T>(props: TableBuilderProps<T>) {
    const { children, data, variant = 'default', alternateRowColors = true, activeIndex, onRowSelect } = props;

    const columns = React.Children.toArray(children)
        .filter((it): it is ReactElement => it !== null && it !== undefined && typeof it === 'object')
        .map((it) => it.props as TableBuilderColumnProps<T>);

    const DynamicTableHeaderComponent = variant === 'default' ? StyledDefaultHeaderCell : StyledInlineHeaderCell;

    return (
        <StyledTable variant={variant}>
            <thead>
                <tr>
                    {columns.map((column, index) => (
                        <DynamicTableHeaderComponent
                            key={index}
                            textAlign={column.numeric ? 'right' : 'left'}
                            width={column.width}
                        >
                            <Text variant="fieldLabel" color="foreground.muted">
                                {column.header}
                            </Text>
                        </DynamicTableHeaderComponent>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data?.map((row, rowIndex) => {
                    if (variant === 'default') {
                        const { isLoading } = props as TableBuilderDefaultVariantProps<T>;
                        return (
                            <StyledDefaultTableBodyRow
                                key={rowIndex}
                                active={activeIndex === rowIndex}
                                selectable={onRowSelect !== undefined}
                                onClick={() => onRowSelect?.(row, rowIndex)}
                                alternateRowColors={alternateRowColors}
                            >
                                {columns.map((column, columnIndex) => (
                                    <DefaultCell
                                        key={columnIndex}
                                        numeric={column.numeric}
                                        interactive={column.interactive}
                                        isLoading={isLoading}
                                        hideOnLoading={column.hideOnLoading}
                                    >
                                        <Text variant="small">{column.children(row, rowIndex)}</Text>
                                    </DefaultCell>
                                ))}
                            </StyledDefaultTableBodyRow>
                        );
                    } else {
                        return (
                            <StyledInlineTableBodyRow key={rowIndex}>
                                {columns.map((column, columnIndex) => (
                                    <InlineCell
                                        key={columnIndex}
                                        numeric={column.numeric}
                                        interactive={column.interactive}
                                    >
                                        <Text variant="small">{column.children(row, rowIndex)}</Text>
                                    </InlineCell>
                                ))}
                            </StyledInlineTableBodyRow>
                        );
                    }
                })}
            </tbody>
        </StyledTable>
    );
}
