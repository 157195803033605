import storage from 'local-storage-fallback';
import { useEffect } from 'react';
import styled from 'styled-components';
import { useSearch } from '@tanstack/react-router';

import { ANONYMOUS_QUOTATION_AUTH_TOKEN_KEY } from '../../common/api/clients/anonymousQuotation.api';
import { FuelShowOfferProductTable } from '../../common/FuelShowOfferProductTable/FuelShowOfferProductTable';
import { translate } from '../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { StatusBar } from '../../common/StatusBar/StatusBar';
import { StatusBarType } from '../../common/StatusBar/StatusBarType';
import { Box } from '../../common/ui/Box';
import { CardVariation } from '../../common/ui/CardVariation';
import { AnonymousOfferEditFormikWrapper } from '../components/AnonymousOfferEditFormikWrapper';
import { AnonymousQuotationAdditionalData } from '../components/AnonymousQuotationAdditionalData';
import { AnonymousQuotationCoreData } from '../components/AnonymousQuotationCoreData';
import { AnonymousQuotationOfferHeader } from '../components/AnonymousQuotationOfferHeader';
import { AnonymousQuotationOfferStatusBar } from '../components/AnonymousQuotationOfferStatusBar';
import { useAnonymousQuotationDetails } from '../hooks/useAnonymousQuotationDetails';

const FullWidthPage = styled(Box).attrs({
    padding: 6,
    minHeight: 'calc(100vh - 80px)',
    backgroundColor: 'background.subtle',
})``;

const setAnonymousQuotationAuthToken = (token) => {
    if (token) {
        storage.setItem(ANONYMOUS_QUOTATION_AUTH_TOKEN_KEY, token);
    }
};

const removeAnonymousQuotationAuthToken = () => {
    storage.removeItem(ANONYMOUS_QUOTATION_AUTH_TOKEN_KEY);
};

export const AnonymousQuotationPage = () => {
    const { anonymousQuotationId } = useAngularRouterLocation();
    const { token } = useSearch({ from: '/limited-quotation/$anonymousQuotationId' });

    const anonymousQuotationQuery = useAnonymousQuotationDetails(anonymousQuotationId, token);

    useDocumentTitle(translate('anonymousQuotation.createOffer'));

    useEffect(() => {
        // set and clear token for AnonymousQuotationService calls
        setAnonymousQuotationAuthToken(token);

        return () => {
            removeAnonymousQuotationAuthToken();
        };
    }, [token]);

    if (!token || anonymousQuotationQuery.isError) {
        return (
            <>
                <AnonymousQuotationOfferHeader offer={anonymousQuotationQuery.data?.offer} />

                <FullWidthPage>
                    <StatusBar
                        headline={translate('anonymousQuotation.invalidAccessToken')}
                        subHeadline={translate('anonymousQuotation.accessToQuotationIsInvalidOrExpired')}
                        type={StatusBarType.DANGER}
                    />
                </FullWidthPage>
            </>
        );
    }

    if (anonymousQuotationQuery.isPending) {
        return (
            <>
                <FullWidthPage>
                    <LoadingIndicator variant="full-height" />
                </FullWidthPage>
            </>
        );
    }

    if (['ENQUIRY', 'QUOTED'].includes(anonymousQuotationQuery.data.offer.state)) {
        return (
            <>
                <AnonymousQuotationOfferHeader offer={anonymousQuotationQuery.data?.offer} />

                <FullWidthPage>
                    <Box display="grid" gap={6}>
                        <AnonymousQuotationOfferStatusBar
                            offer={anonymousQuotationQuery.data.offer}
                            anonymousQuotationId={anonymousQuotationQuery.data.id}
                        />

                        <CardVariation>
                            <AnonymousQuotationCoreData
                                data={{
                                    offer: anonymousQuotationQuery.data.offer,
                                    messages: anonymousQuotationQuery.data.messages,
                                }}
                            />
                        </CardVariation>

                        <AnonymousOfferEditFormikWrapper
                            offer={anonymousQuotationQuery.data.offer}
                            anonymousQuotationId={anonymousQuotationQuery.data.id}
                        />
                    </Box>
                </FullWidthPage>
            </>
        );
    }

    return (
        <>
            <AnonymousQuotationOfferHeader offer={anonymousQuotationQuery.data.offer} />

            <FullWidthPage>
                <Box marginBottom={6}>
                    <AnonymousQuotationOfferStatusBar offer={anonymousQuotationQuery.data.offer} />
                </Box>

                <Box display="flex" flexDirection="column" gap={6}>
                    <CardVariation marginBottom={6}>
                        <AnonymousQuotationCoreData
                            data={{
                                offer: anonymousQuotationQuery.data.offer,
                                messages: anonymousQuotationQuery.data.messages,
                            }}
                        />
                    </CardVariation>
                    <CardVariation marginBottom={6}>
                        <FuelShowOfferProductTable offer={anonymousQuotationQuery.data.offer} />
                    </CardVariation>
                    <CardVariation>
                        <AnonymousQuotationAdditionalData offer={anonymousQuotationQuery.data.offer} />
                    </CardVariation>
                </Box>
            </FullWidthPage>
        </>
    );
};
